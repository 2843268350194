import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const RomanBlinds = () => (
  <React.Fragment>
    <Helmet>
      <title>Roman Blinds | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Roman Blinds | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Roller Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Roller Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="http://birk.itechnotion.com/roman-blinds"
      />
      <link rel="canonical" href="http://birk.itechnotion.com/roman-blinds" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Roman Blinds</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">
                    <span className="font-weight-bold">Roman Blinds</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Think of roman blinds as the origami of window coverings.
                    Fabric is folded with almost architectural precision, as
                    your blinds vertically concertina to reveal an outside
                    aspect or to simply flood the interior with natural light.
                    Alternatively use a Birk blockout and, at full extension,
                    help give your home more privacy and greater insulation.
                  </p>
                  <p className="text-justify">
                    Birk roman blinds are a popular choice to dress living rooms
                    and master suites. Certainly they’re practical but they’re
                    also personal, that’s why they work so well in lounging
                    areas. Choose between sophisticated, textured materials or
                    opt for lighter fabrics to blend with more neutral decor.
                  </p>
                  <p className="text-justify">
                    There are effectively two ‘types’ of roman blinds – Birk
                    classifies these as ‘hard’ and ‘soft’. A hard roman blind is
                    a fabric which has been specifically designed for use in
                    blinds and often has a stiffening or blockout coating on it.
                    These blinds often give a sharper look when made up due to
                    the structure and stiffness of the fabric. A soft roman
                    blind is taking a drapery or curtain fabric and crafting a
                    blockout lining into the back of the blind. This finish has
                    a much softer look and opens up a substantially larger range
                    of options as there are many more drapery or curtain fabrics
                    on the market than blind specific fabrics.
                  </p>
                  <p className="text-justify mb-3">
                    With Birk roman blinds, you can select from over 50 styles.
                    Add a kaleidoscope of colour combinations and you begin to
                    appreciate why Birk blends so beautifully with you and your
                    home.
                  </p>
                  <p>
                    Your Birk roman blinds can be customised in a range of
                    affordable and fashionable fabrics. Read more about our
                    Essentials Range and Classic Range.
                  </p>
                  <p>
                    For even greater choice, elevate your interiors by choosing
                    from our Supreme Range.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">One Touch</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Motorisation</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Back batten</p>
                </div>
              </div>
            </div>
            <div className="why-list why-list2 ml-4">
              <ul>
                <li>Adds insulation</li>
                <li>Simple to operate</li>
                <li>Total privacy when drawn</li>
              </ul>
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bromans1.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bromans2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bromans3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default RomanBlinds;
